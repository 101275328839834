<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useOtp } from '/~/extensions/otp/composables'
import {
  useKyc,
  useKycComponent,
} from '/~/extensions/user-kyc/composables/use-kyc'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useUser } from '/~/composables/user'

const router = useRouter()
const { isEnrolmentOtpEnabled } = useOtp()
const { isKycEnabled } = useKyc()
const { user, fetchUserDetails } = useUser()
const { kycService } = useKycComponent()

const initiatingVerification = ref(false)

const isVerificationRequired = computed(() => {
  return (
    (isEnrolmentOtpEnabled.value && user.value.hasUnfinishedOtpVerifications) ||
    (isKycEnabled.value && !user.value.hasFinishedIdentityVerifications)
  )
})

async function onVerify() {
  if (isKycEnabled.value && !isEnrolmentOtpEnabled.value) {
    initiatingVerification.value = true

    try {
      if (!(await kycService.createVerificationSession())) {
        initiatingVerification.value = false
        return
      }
      kycService.syncVerificationSessions()
      fetchUserDetails()
      router.push({ hash: '#profile-verification' })
    } finally {
      initiatingVerification.value = false
    }
  } else {
    router.push({ hash: '#profile-verification' })
  }
}
</script>

<template>
  <div
    v-if="isVerificationRequired"
    class="flex flex-wrap items-center justify-between gap-3 rounded-md bg-orange-50 px-4 py-3 text-orange-700 shadow sm:flex-nowrap"
  >
    <div class="flex items-center gap-x-3">
      <base-icon
        svg="plain/clock-v3"
        class="flex-shrink-0 text-orange-700"
        :size="32"
      />
      <div>
        As part of our ongoing security improvements, please verify your
        identity to ensure uninterrupted service.
      </div>
    </div>

    <base-button
      look="outlined-color"
      class="!h-8 w-full justify-self-end sm:w-auto"
      size="xs"
      color="none"
      :loading="initiatingVerification"
      @click="onVerify"
    >
      Verify now
    </base-button>
  </div>
</template>
